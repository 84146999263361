<template>
  <div class="color-choose-img" :class="{ 'product-not-loaded': !product }">
    <div class="d-flex align-items-center justify-content-start pb-3 pr-1">
      <span class="fontsize16 fontsize-md-18 text-color-444"
        >رنگ : {{ colorName }}</span
      >
    </div>
    <div v-if="product" class="d-flex">
      <button
        :disabled="!product.isColorAvailable(color)"
        :selected="product.isColorSelected(color)"
        v-for="color in product.getColors()"
        :key="color.id"
        @click="selectColor(color)"
        class="color-btn"
      >
        <div
          class="product-color-choose-img"
          :style="'background: ' + color.code"
        >
          <svg
            v-if="product.isColorSelected(color)"
            id="colorBtnCheck"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            style="transform: ; msfilter: "
            :fill="filled"
          >
            <path
              d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"
            ></path>
          </svg>
        </div>
      </button>
    </div>
    <div v-else>
      <button class="overflow-hidden mb-2 color-btn"></button>
      <button class="overflow-hidden mb-2 color-btn"></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductColors",
  props: {
    value: Object,
  },
  data() {
    return {
      product: this.value,
      r: null,
      g: null,
      b: null,
      hsp: null,
      brightness: null,
      filled: null,
      colorName: null,
    };
  },
  watch: {
    value(newVal) {
      this.product = newVal;
      if (this.product) {
        this.selectDefaultColor();
      }
    },
    product(newVal) {
      this.$emit("input", newVal);
    },
  },
  mounted() {
    if (this.product) {
      this.selectDefaultColor();
    }
  },
  methods: {
    selectColor(color) {
      this.adjustTextColor(color);
      this.colorName = color.name;
      this.product.selectColor(color);
    },
    selectDefaultColor() {
      // انتخاب اولین رنگ
      let colors = this.product.getColors();
      for (let [index, color] of Object.entries(colors)) {
        if (this.product.isColorAvailable(color)) {
          this.colorName = color.name;
          this.product.selectColor(color);
          break;
        }
      }
    },
    adjustTextColor(color) {
      // ست کردن کد رنگ
      let bgColor = color.code;

      // صدا زدن تابع زیر برای تشخیص روشن یا تیره بودن رنگ
      this.brightness = this.lightOrDark(bgColor);

      if (this.brightness == "dark") {
        this.filled = "#ffffff";
      } else {
        this.filled = "#000000";
      }
    },
    lightOrDark(color) {
      // چک میکنه فرمت رنگ RGB یا HEX
      if (color.match(/^rgb/)) {
        // اگر HEX --> مقادیر قرمز، سبز و آبی را در متغیرهای جداگانه ذخیره می کند
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        this.r = color[1];
        this.g = color[2];
        this.b = color[3];
      } else {
        // اگر RGB --> تبدیل کن به HEX : http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        this.r = color >> 16;
        this.g = (color >> 8) & 255;
        this.b = color & 255;
      }

      // HSP یک سیستم رنگی برای بررسی مقدار روشنایی رنگ http://alienryderflex.com/hsp.html
      this.hsp = Math.sqrt(
        0.299 * (this.r * this.r) +
          0.587 * (this.g * this.g) +
          0.114 * (this.b * this.b)
      );

      if (this.hsp > 127.5) {
        return "light";
      } else {
        return "dark";
      }
    },
  },
};
</script>

<style scoped>
.color-btn {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 0.5rem;
  box-shadow: 0 0 7px -2px var(--color-theme3);
}
.product-color-choose-img {
  position: relative;
  height: 100px;
  width: 100%;
  background-image: radial-gradient(#fafafa, #f4f4f4, #e1e1e1);
}

.product-color-choose-img img {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
#colorBtnCheck {
  position: absolute;
  top: 4px;
  right: 5px;
}
.product-color-choose-bottom {
  background-color: #dedede;
  text-align: center;
  font-size: 14px;
  height: 26px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.2s all;
}

.color-btn:not(selected=selected):hover .product-color-choose-bottom {
  background-color: var(--color-theme);
  color: white !important;
}

/* وقتی انتخاب شد چه شکلی بشه */
.color-btn[selected="selected"] .product-color-choose-bottom {
  background-image: radial-gradient(#fafafa, #f4f4f4, #e1e1e1);
  /*color: white!important;*/
  height: 29px;
}

.color-btn[selected="selected"] .product-color-choose-img {
  height: 100px;
}

.color-btn[selected="selected"] {
  /*box-shadow: 0 0 5px 1px #923131;*/
}

.color-btn:hover {
  box-shadow: 0 0 7px -1px var(--color-theme3);
}

.color-btn {
  width: 40px;
  height: 40px;
  text-align: center;
  margin-left: 10px;
  /*box-sizing: border-box !important;*/
  transition: 0.3s;
}

.icon {
  margin-left: 4px;
  transform: translateY(2px);
}

.color-btn:disabled {
  opacity: 0;
}

.canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.color-choose-img > div {
  min-width: 300px;
}
.light-fill {
  fill: #ffffff !important;
}
.dark-fill {
  fill: #000000 !important;
}
@media (max-width: 768px) {
  .color-choose-img > div {
    min-width: unset;
  }

  .color-choose-img {
    max-width: 60vw;
  }
}

@media (max-width: 500px) {
  .color-choose-img {
    max-width: 88vw;
  }
}

.product-not-loaded.color-choose-img {
  max-width: 450px;
}

.color-choose-img {
  scrollbar-color: var(--color-theme) var(--color-theme2);
  scrollbsr-width: thin;
}

.color-choose-img button {
  flex-shrink: 0;
}
</style>
