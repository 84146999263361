<template>
  <section id="categorySection" v-if="categories!=null"
           class="products-categories d-md-flex d-none mx-sm-3  justify-content-start bg-white ">
<!-- 
           <div id="sliderCategorry" class="swiper-category overflow-hidden">
       
            <div class="swiper-wrapper">
       
         
              
          
              <template v-for="(category,index) in categories" >
            
                <div class="swiper-slide">

                 
                      <article  :key="category.id">
                        <router-link :to="{
                          name:'product.category',
                         params:{ category:category.id,slug:category.slug}
                        }" class="d-flex px-md-4 px-0 px-sm-2 align-items-center " >
                          <span class="products-categories-svg pr-sm-2 pr-0">
                            <img v-if="category.icon" width="31" height="31" :src="category.icon.url" :alt="category.title">
                          </span>
                          <div class="text-center">
                            <h6 class="text-color-444 weight-bold fontsize-medium mt-2">{{category.title}}</h6>
                            <h6 class="text-color-444 fontsize-mini text-color-666 mt-n1">{{category.en_title}}</h6>
                          </div>
                        </router-link>
                      </article>
                </div>
              </template>
            </div>
           
          </div> -->
          <!-- <div class="swiper">
       
            <div class="swiper-wrapper">
              <div class="swiper-slide">Slide 1</div>
              <div class="swiper-slide">Slide 2</div>
              <div class="swiper-slide">Slide 3</div>
              ...
            </div>
           
            <div class="swiper-pagination"></div>
          
       
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          
  
            <div class="swiper-scrollbar"></div>
          </div> -->
  <template v-for="(category,index) in categories" >


<!--    :class="{'mobileSizeCategory':index>4,'hiddenItem':index>6}"-->
    <article  class="item-category" :key="category.id">
      <router-link :to="{
        name:'product.category',
       params:{ category:category.id,slug:slugGenerator(category)}
      }" class="d-flex px-md-4 px-0 px-sm-2 align-items-center " >
        <span class="products-categories-svg pr-sm-2 pr-0">
          <img v-if="category.icon" width="31" height="31" :src="category.icon.url" :alt="category.title">
        </span>
        <div class="text-center">
          <h6 class="text-color-444 weight-bold fontsize-medium mt-2">{{category.title}}</h6>
          <h6 class="text-color-444 fontsize-mini text-color-666 mt-n1">{{category.en_title}}</h6>
        </div>
      </router-link>
    </article>
  </template>
  </section>

</template>

<script>
// import Swiper from "swiper/swiper-bundle.min";
export default {
  name: "ProductCategories",
  inject: ['mediaQueries'],
  computed:{
    showCount() {
      if (this.mediaQueries.xxxLarge) {
        return 10
      }
      if (this.mediaQueries.xxLarge) {
        return 9;
      }
      // if (this.mediaQueries.xLarge) {
      //   return 7;
      // }
      // if (this.mediaQueries.xxSmall) {
      //   return 3
      // }
      // if (this.mediaQueries.xSmall) {
      //   return 4
      // }
      // if (this.mediaQueries.medium) {
      //   return 6
      // }
      return 9
    },
    // دریافت دسته بندی ها از پاسخ دریافت شده در صفحه اصلی
    categories(){
      if(this.$store.getters["front/getHomeData"]==null){
        return null;
      }else{
        return this.getEnough(this.$store.getters["front/getHomeData"].special_categories);
      }
    }
  },
  // watch: {
  //   categories(newValue) {
  //     if (newValue) {
  //       this.slider(true);
  //     }
  //   },
  // },
  methods: {
    // گرفتن مقدار لازم براساس تعدادی که باید نمایش بدیم بر اساس سایز صفحه
    getEnough(array) {
      return [...array].splice(0, Math.min(array.length, this.showCount));
    },
    slugGenerator(category){
      if(category.slug){
        return category.slug
      }else{
        return category.title.replaceAll(" ","_")
      }
    },
    // slider(kill = false) {
    //   this.initSwiper("myamazingSuggest", kill, () => {
    //     new Swiper("#sliderCategorry.swiper-category", {
    //       slidesPerView: 6,
    //           spaceBetween: 0,
    //       // centeredSlides: true,
    //       loop: true,
    //       speed: 500,
    //       autoplay: {
    //         delay: 5000,
    //         disableOnInteraction: false,
    //       },
    //       pagination: {
    //         el: ".similar-products .swiper-pagination",
    //         clickable: true,
    //       },
    //       breakpoints: {
    //         250: {
    //           slidesPerView: 2,
    //           spaceBetween: 5,
    //         },
    //         370: {
    //           slidesPerView: 2,
    //           spaceBetween: 5,
    //         },
    //         500: {
    //           slidesPerView: 3,
    //           spaceBetween: 5,
    //         },
    //         768: {
    //           slidesPerView: 5,
    //           spaceBetween: 8,
    //         },
    //         1000: {
    //           slidesPerView: 6,
    //           spaceBetween: 8,
    //         },
    //         1200: {
    //           slidesPerView: 6,
    //           spaceBetween: 0,
    //         },
    //       },
    //     });
    //   });
    // },

    // slider(){
    //   const swiper =  new Swiper(".swiper", {
    //     slidesPerView: 7,
    //           spaceBetween: 0,
    //       // centeredSlides: true,
    //       loop: true,
    //       speed: 500,
    //       autoplay: {
    //         delay: 5000,
    //         disableOnInteraction: false,
    //       },
        
    //       breakpoints: {
    //         250: {
    //           slidesPerView: 2,
    //           spaceBetween: 0,
    //         },
    //         370: {
    //           slidesPerView: 2,
    //           spaceBetween: 0,
    //         },
    //         500: {
    //           slidesPerView: 3,
    //           spaceBetween: 0,
    //         },
    //         768: {
    //           slidesPerView: 5,
    //           spaceBetween: 0,
    //         },
    //         1000: {
    //           slidesPerView: 6,
    //           spaceBetween: 0,
    //         },
    //         1200: {
    //           slidesPerView: 7,
    //           spaceBetween: 0,
    //         },
    //       },
    //     });
    // }
  }
  ,
  // mounted(){
  //   this.slider()
  // }
}
</script>

<style scoped>

/********** products-categories *********/

.products-categories {
  box-shadow: 0 0 25px #dddddd99;
  position: absolute;
  bottom: -50px;
  z-index: 2;
}


/* .products-categories article>a svg>path{
transition: .3s;
} */

.products-categories article>a:hover svg>path {
  fill: white !important;
  stroke: white !important;
}

.products-categories .products-categories-svg {
  background-image: url(../../../assets/images/blob-shape.png);
  background-size: 100% 100%;
  transition: .3S;
  margin-left: 12px;
}
@media screen and (max-width: 769px){
  .products-categories .products-categories-svg {
    margin-left: 3px;
  }
}

.products-categories article>a:hover .products-categories-svg {
  background-image: url(../../../assets/images/blob-shape2.png);
}


.products-categories a {
  /* min-width: 150px; */
  height: 100px;
  transition: .3S;
  display: flex;
  justify-content: center;
}

.products-categories a {
  --transition-duration: 500ms;
  --transition-easing: ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--foreground-color);
  font-size: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.1);

  /*box-shadow: 0px calc(var(--size) / 6) calc(var(--size) / 3) rgba(0, 0, 0, 0.1);*/
  transition: transform var(--transition-duration) var(--transition-easing);
  overflow: hidden;
  width: 100%;
}
.products-categories a:before {
  content: "";
  left: -125%;

}
.products-categories a:hover::before {
  background: rgba(255, 255, 255, 0.4);
  width: 60%;
  height: 100%;
  top: 0%;
  transform: skew(45deg);
  position: absolute;
  transition: left var(--transition-duration) var(--transition-easing);
}

.products-categories a:hover {
  backdrop-filter: blur(2px);
}
.products-categories a:hover::before {
  left: 150%;
}

.products-categories a svg>path,
.products-categories a h6 {
  transition: .3s;
}

.products-categories article:not(:last-child) {
  border-left: 1px solid var(--color-eai);
}

.products-categories article>a:hover {
  background-color: var(--color-theme);
}

.products-categories article>a:hover h6 {
  color: white !important;
}

.products-categories article>a:hover svg>path {
  stroke: white;
}

#categorySection{
  max-height: 96px;
  overflow: hidden;
}
#categorySection::-webkit-scrollbar{
  display: none;
}
.hiddenItem{
  /* عدم نمایش دسته بندی ها اگر  بیشتر از هفتا بودند */
  display: none;
}
/* درست کردن قالب در سایز401  */
@media (max-width:500px){

.mobileSizeCategory{
  display: none;
}
}
@media (max-width:1000px){

#categorySection{
  width: 97% !important;
}

}


/* درست کردن قالب در سایز 766 */
@media (min-width:767px){
  #categorySection{

display: flex !important;
    justify-content: center!important;
  }
}
@media (max-width:767px){

  #categorySection{
    display: flex;
    overflow: scroll!important;
    max-width: 87%;
    flex-wrap: nowrap!important;
  }
  .products-categories > article {
    min-width: 115px!important;
  }

  .products-categories-svg>svg {
    width: 17px !important;
    height: 17px !important;
  }
  /* .products-categories h6:first-child {
      font-size: 11px !important;
  } */
  .products-categories a {
    height: 80px;
    width: 100%;
  }
  .products-categories {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    bottom: -40px;
  }
  .products-categories h6:nth-child(2) {
    font-size: 9px;
  }
}

@media (max-width: 575px) {
  .products-categories article div>h6:nth-child(2) {
    display: none;
  }
  .products-categories {
    bottom: -30px;
  }
  .products-categories a {
    height: 60px;
  }
}

@media screen and (max-width: 360px){
  #categorySection{
    max-width: 83%;
  }
}

/* #categorySection > article {
  flex: 1 1;
} */

.products-categories > article {
  min-width: 133px;
}


</style>
