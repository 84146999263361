<template>
  <section
    id="storyCategorySection "
    v-if="categories && categories.length"
    class="products-categories d-flex  mx-sm-3 mr-2 flex-nowrap justify-content-start bg-transparent my-4"
    style="gap: 1rem;overflow-x: scroll;"
  >
    <template v-for="(category, index) in categories">
      <article :key="category.id">
        <router-link
          :to="{
            name: 'product.category',
            params: { category: category.id },
          }"
          class="d-flex flex-column cateItem align-items-center"
        >
          <span class="products-categories-img rounded-circle">
            <img
              class="rounded-circle box-shaddow20"
              v-if="category.image"
              
              :src="category.image.url"
              :alt="category.title"
            />
          </span>
          <div class="text-center">
            <h6
              class="text-color-444 font-weight-light fontsize14 mt-2 text-nowrap"
            >
              {{ category.title }}
            </h6>
          </div>
        </router-link>
      </article>
    </template>
  </section>
</template>

<script>
export default {
  name: "storyCategories",
  inject: ["mediaQueries"],
  computed: {
    showCount() {
      if (this.mediaQueries.xxxLarge) {
        return 10;
      }
      if (this.mediaQueries.xxLarge) {
        return 9;
      }
      return 9;
    },
    // دریافت دسته بندی ویژه
    categories() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return null;
      } else {
        return this.getEnough(
          this.$store.getters["front/getHomeData"].special_categories
        );
      }
    },
  },
  methods: {
    // گرفتن مقدار لازم براساس تعدادی که باید نمایش بدیم بر اساس سایز صفحه
    getEnough(array) {
      return [...array].splice(0, Math.min(array.length, this.showCount));
    },
  },
};
</script>

<style scoped>
.products-categories::-webkit-scrollbar{
  display: none;
}
.products-categories-img img {
 /* background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(45deg, purple, orange) border-box;*/
    background-color: #fff;
  border: 3px solid transparent;
  border-radius: 50px;
  width: 90px;
  height: 90px;
}
#storyCategorySection {
  overflow-x: scroll !important;
  max-width: 99%;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  -ms-overflow-style: none;  /* برای edge */
  scrollbar-width: none; /* برای فایرفاکس*/
}
#storyCategorySection::-webkit-scrollbar {
  display: none;/* برای فایرفاکس*/

}
.cateItem{
  margin: 0px 15px;
}

@media screen and (max-width: 1000px) {
  .products-categories-img img {
    background-color: #fff;
    border: 3px solid transparent;
    border-radius: 50px;
    width: 60px;
    height: 60px;
  }
  .cateItem{
  margin: 0px 0px;
}
}
#storyCategorySection {
  overflow-x: hidden;
 }
@media screen and (max-width: 992px) {
 #storyCategorySection {
  overflow-x: hidden;
 }
}
</style>
