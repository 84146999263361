<template>
  <section
    class="product-details-img d-flex border-lg-left mb-lg-0 mb-4"
  >
    <div
      @scroll="isScrolling"
      class="product-details-s-img ml-2"
      v-dragscroll="!mediaQueries.mobileSize"
    >
      <div v-if="galleries != null" class="d-flex flex-column">
        <!-- <a
          class="small-pic"
          v-if="!Array.isArray(video) && !Array.isArray(cover)"
          :active="currentIndex === index"
          @click="showVideo(video.url)"
        >
          <img loading="lazy" :src="cover.url"
        /></a> -->

        <a
          class="small-pic"
          v-for="(image, index) in galleries"
          :key="image.id"
          :active="currentIndex === index"
          @click="goToSlide(index)"
          @mouseenter="goToSlide(index)"
        >
          <img loading="lazy" class="border-radius10" :src="getImage(image, 'md')"
        /></a>
      </div>
      <div v-else class="d-flex flex-column ">
        <a></a>
        <a></a>
        <a></a>
        <a></a>
      </div>
      <!-- <button
        class="videoPlayer"
        v-if="!Array.isArray(video)"
        @click="showVideo(video.url)"
      >
        <img src="@/assets/images/player.gif" alt="video player" />
      </button> -->
    </div>

    <div
      @wheel="isWheeling"
      class="product-details-l-img "
      :style="
        galleries == null
          ? 'background-image: radial-gradient(#f6f6f6,#f9f9f9, #f6f6f6)'
          : ''
      "
    >
      <!-- Swiper -->
      <div
        v-if="galleries != null"
        @mousemove="mousemove"
        @mouseover="mouseover"
        @mouseleave="mouseleave"
        class="swiper-container gallery-swiper h-100 mySwiper"
      >
        <div class="swiper-wrapper">
          <div v-for="image in galleries" :key="image.id" class="swiper-slide">
            <img
              v-if="mediaQueries.large"
              :src="getImage(image, 'lg')"
              @click="openViewer(image)"
              loading="lazy"
              class="main"
              :style="'cursor: zoom-in'"
            />
            <PicZoom
              @clicked="openViewer(image)"
              v-if="!mediaQueries.large && galleries != null"
              box=".gallery-swiper"
              ref="piczoom"
              class="main"
              :url="image.url"
            />
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
      <div v-else class="h-100">
        <div class="swiper-slide p-2"></div>
      </div>
      <!-- دکمه ست -->
      <!-- <button v-if="sets.length != 0"
        @click="scrollToSetSection({ behavior: 'smooth' })"
        class="product-set-btn"
      >
        <span class="setter-for-product-detail">با چی ست کنم؟</span
        ><bottom-arrow />
      </button> -->
      <!-- دکمه ست -->
    </div>
    <!-- <div id="showVideoBox" v-if="showVideoBox">
      <button id="closeVideoModal" @click="showVideoBox = false">
        <svg
          fill="#000000"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
          width="30px"
          height="30px"
        >
          <path
            d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"
          />
        </svg>
      </button>
      <video class="videoContent" width="100%" height="100%" controls>
        <source :src="playerOptions" type="video/mp4" />
      </video>
    </div> -->
    <SilentBox ref="silentBox" :gallery="galleryFriendlyImages" />
  </section>
</template>
<script>
import Swiper from "swiper/swiper-bundle";
import { hasClass } from "@/libraries/VueMethods";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";
import SilentBox from "@/components/gallery/components/Gallery";
import PicZoom from "@/components/piczoom/PicZoom";
import { getImage } from "@/libraries/functions";
import productSetsBTN from "@/parts/Front/components/productDetail/ProductSetButton"; 
// import productSets from "@/components/productDetail/ProductSets";

export default {
  props: ["video", "cover", "galleries", "selectedVariety" , "sets"],
  inject: ["mediaQueries"],
  data() {
    return {
      playerOptions: null,
      showVideoBox: false,
      swiper: null,
      // If is scrolling dont change image
      scrolling: false,
    };
  },
  components: { Viewer, SilentBox, PicZoom, productSetsBTN },
  computed: {
    currentIndex() {
      if (!this.swiper) {
        return -1;
      }
      return this.swiper.realIndex;
    },
    galleryFriendlyImages() {
      return this.galleries
        ? this.galleries.map((g) => ({ src: g.url, id: g.id }))
        : [];
    },
  },
  watch: {
    galleries(newVal) {
      if (newVal == null) {
        return;
      }
      ////swiper slider of product details
 
       this.initSlider();
  
    },
    selectedVariety(selectedVariety) {
      // بریم به اولین عکس اون تنوع
      if (selectedVariety) {
        let index = this.galleries.findIndex((image) => {
          return image.variety_id == selectedVariety.id;
        });
        if (index > -1) {
          this.$nextTick(() => {
            this.goToSlide(index);
          });
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.removeEventListener("resize", this.onResize);
    window.addEventListener("resize", this.onResize);
    this.$nextTick(()=>{
      this.initSlider();
    })
  },
  methods: {
    initSlider(){
      if (this.swiper) {
          this.swiper.destroy();
        }
        this.swiper = new Swiper(".product-details-l-img .swiper-container", {
          spaceBetween: 1,
          //   centeredSlides: true,
          loop: false,
          speed: 500,

          pagination: {
            el: ".product-details-l-img .swiper-pagination",
            clickable: true,
          },
        })
      },
    scrollToSetSection() {
      document
        .getElementsByClassName("set-products")[0]
        .scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
    },
    showVideo(video) {
      this.playerOptions = video;
      this.showVideoBox = true;
    },
    getImage,
    mousemove(e) {
      this.$refs.piczoom && this.$refs.piczoom[this.currentIndex].mousemove(e);
    },
    mouseover(e) {
      this.$refs.piczoom && this.$refs.piczoom[this.currentIndex].mouseover(e);
    },
    mouseleave(e) {
      this.$refs.piczoom && this.$refs.piczoom[this.currentIndex].mouseleave(e);
    },
    onResize() {
      const bodyWidth = document.body.offsetWidth;
      const productLargeImg = document.querySelectorAll(
        ".product-details-l-img"
      )[0];
      const productSmallImg = document.querySelectorAll(
        ".product-details-s-img"
      )[0];
      const productSmallImgItem = document.querySelectorAll(
        ".product-details-s-img a"
      );
      if (productLargeImg && productSmallImg) {
        if (bodyWidth < 576) {
          for (const productSmlImg of productSmallImgItem) {
            productSmlImg.style.height = productSmlImg.offsetWidth + "px";
          }
        }
        if (bodyWidth < 400) {
          productLargeImg.style.height =
            productLargeImg.offsetWidth / 0.75 + "px";
          productSmallImg.style.height =
            productLargeImg.offsetWidth / 0.75 + "px";
          for (const productSmlImg of productSmallImgItem) {
            productSmlImg.style.height = productSmlImg.offsetWidth + "px";
          }
        }
      }
    },
    goToSlide(slideNumber) {
      if (this.scrolling && this.scrolling + 150 > new Date().getTime()) {
        return;
      }
      document
        .querySelectorAll(".mouse-cover-canvas")
        .forEach((el) => (el.style.display = "none"));

      this.swiper.slideTo(slideNumber);
    },
    // برای رفع باگ کلیک شدن موقع اسکرول
    isScrolling() {
      this.scrolling = new Date().getTime();
    },
    // Wheeling with mouse event
    isWheeling(e) {
      let deltaY = e.deltaY;
      let preventDefault = true;
      if (deltaY < -40 && this.currentIndex > 0) {
        this.goToSlide(this.currentIndex - 1);
      } else if (deltaY > 40 && this.currentIndex < this.galleries.length - 1) {
        this.goToSlide(this.currentIndex + 1);
      } else {
        preventDefault = false;
      }
      if (preventDefault) {
        e.preventDefault();
      } else {
        window.scroll({
          top: window.scrollY + (deltaY > 40 ? 200 : -200),
          left: window.scrollX,
          behavior: "smooth",
        });
      }
    },
    openViewer(image) {
      let index = this.galleryFriendlyImages.findIndex(
        (item) => item.src == image.url
      );
      this.$refs.silentBox.openOverlay(
        this.galleryFriendlyImages[index],
        index
      );
      // let galleries = [...this.galleries]
      // galleries.sort((a,b) => {
      //   if (a.id == image.id) return -1;
      //   if (b.id != image.id) return 1;
      //   return 0;
      // });
      // this.$viewerApi({
      //   images: galleries.map(image => image.url),
      //   options: {
      //     zoomable:false,
      //     rotatable: false,
      //     navbar: true,
      //     scalable: false,
      //     title: false
      //   }
      // })
    },
  },
};
</script>
<style scoped>
.setter-for-product-detail {
  white-space: nowrap;
}
.product-details-img {
  gap: 16px;
}

.product-details-l-img img.main {
  max-width: 100%;
  max-height: 100%;
  /* width: 100%;
    height: 100%; */
  position: relative;
  /*top: 50%;*/
  top: 0;
  /*transform: translateY(-50%);*/
}

.small-pic {
  transform: scale(1);
  transition: 200ms all;
  border-radius: 10px;

}
.small-pic[active] {
  /*border: 1px solid #8d8d8d;*/
  border: 0px solid #000000;
  box-shadow: 0 0 0px 2px #000000;
  transform: scale(1.03);
  border-radius: 10px;
}

.enlarge {
  cursor: pointer;
  position: absolute;
  width: 27px;
  height: 27px;
  left: 10px;
  top: 10px;
  padding: 6px;
  background: white;
  border-radius: 50%;
  transition: 200ms all;
  user-select: none;
}
@media screen and (max-width: 992px) {
  .product-set-btn {
    bottom: 30px !important;
  }
}
@media screen and (max-width: 575px) {
  .product-set-btn {
    bottom: 0 !important;
  }
}
.product-set-btn {
  position: absolute;
  padding: 10px 20px;
  border: 3px solid white;
  border-radius: 34px;
  bottom: 0;
  transform: translate(50%, 50%);
  color: white; 
  z-index: 5;
  background-color: black;
}
.enlarge:hover {
  width: 29px;
  height: 29px;
  left: 9px;
  top: 9px;
}

.enlarge img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: baseline;
  user-select: none;
}
.product-details-s-img {
  position: relative;
}
button.videoPlayer {
  top: 40px;
}
#showVideoBox {
  position: fixed;
  z-index: 1000;
}
@media (max-width: 768px) {
  button.videoPlayer {
    width: 50px;
    top: 30px;
  }
}
</style>
<style>
.zoom-on-hover {
  height: 100%;
}

.zoom-on-hover .normal {
  height: 100%;
}


.product-details-img img.zoom {
  cursor: zoom-out;
}

.viewer-canvas > img {
  left: 50%;
  transform: translateX(-50%) !important;
  margin-left: unset !important;
}

.viewer-list {
  width: 100% !important;
}

.viewer-list > li {
  min-width: 40px !important;
}

.viewer-reset {
  display: none !important;
}
</style>
