<template>
  <div class="d-flex flex-wrap justify-content-xl-between fontsize-small order-xl-1 order-2">
    <button :disabled="filter.sort == 'most_visited' || loading"
            :class="filter.sort == 'most_visited' ? 'view-order-active' : ''"
            @click="filter.sort = 'most_visited' , productsFilter()" type="button"
            class="ml-1 py-2 px-3 text-color-999  ">
      پربازدید ترین
    </button>
    <button :disabled="filter.sort == 'low_to_high' || loading"
            :class="filter.sort == 'low_to_high' ? 'view-order-active' : ''"
            @click="filter.sort = 'low_to_high' , productsFilter()" type="button"
            class="ml-1 py-2 px-3 text-color-999  ">
      ارزان ترین
    </button>
    <button :disabled="filter.sort == 'high_to_low' || loading"
            :class="filter.sort == 'high_to_low' ? 'view-order-active' : ''"
            @click="filter.sort = 'high_to_low' , productsFilter()" type="button"
            class="ml-1 py-2 px-3 text-color-999  ">
      گران ترین
    </button>
    <button :disabled="filter.sort == 'top_sales' || loading"
            :class="filter.sort == 'top_sales' ? 'view-order-active' : ''"
            @click="filter.sort = 'top_sales' , productsFilter()" type="button"
            class="ml-1 py-2 px-3 text-color-999  ">
      پرفروش ترین
    </button>
    <button :disabled="filter.sort == 'newest' || loading"
            :class="filter.sort == 'newest' ? 'view-order-active' : ''"
            @click="filter.sort = 'newest' , productsFilter()" type="button"
            class="ml-1 py-2 px-3 text-color-999  ">
      جدید ترین
    </button>
    <button :disabled="filter.sort == 'most_discount' || loading"
            :class="filter.sort == 'most_discount' ? 'view-order-active' : ''"
            @click="filter.sort = 'most_discount' , productsFilter()" type="button"
            class="ml-1 py-2 px-3 text-color-999  ">
      ویژه
    </button>
  </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: "ProductsSort",
  props: {
    value: Object,
    loading: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      filter: cloneDeep(this.value)
    }
  },
  watch: {
      value(newVal) {
        this.filter = cloneDeep(newVal)
      }
  },
  computed: {
    disabled() {
      return this.$store.getters['front/getProductsStatus']
    },
  },
  methods: {
    productsFilter() {
      this.filter.page = 1
      this.$emit('input', this.filter)
    }
  }
}
</script>
<style scoped>
.view-order-active {
  color: var(--color-white) !important;
  background-color: var(--color-theme-main) !important;
  border-radius: 10px;
}
</style>
