<template>
  <div class="cart-btn bg-none ml-2 headerIcon">
    <!-- <cartSvg /> -->
    <svg width="27" height="27" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.7729 9.30504V6.27304C15.7729 4.18904 14.0839 2.50004 12.0009 2.50004C9.91691 2.49104 8.21991 4.17204 8.21091 6.25604V6.27304V9.30504" stroke="#444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M16.7422 21.0003H7.25778C4.90569 21.0003 3 19.0953 3 16.7453V11.2293C3 8.87933 4.90569 6.97433 7.25778 6.97433H16.7422C19.0943 6.97433 21 8.87933 21 11.2293V16.7453C21 19.0953 19.0943 21.0003 16.7422 21.0003Z" stroke="#444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
    <span
      v-if="quantity != 0"
      class="rounded-circle bg-color-themeRed text-white fontsize12 weight-bold"
    >
      {{ quantity }}</span
    >
  </div>
</template>
<script>
import cartSvg from "../svg/Cart.vue";

import { cartListsOpen } from "@/libraries/functions";

export default {
  components: {
    cartSvg,
  },
  computed: {
    cart() {
      if (
        this.$store.getters["front/getHomeData"] != null &&
        this.$store.getters["front/getHomeData"].user.cart.cartItems != null
      ) {
        return this.$store.getters["front/getHomeData"].user.cart;
      } else {
        return null;
      }
    },
    quantity() {
      if (this.cart) {
        return this.cart.cartItems.reduce((sum, item) => {
          sum += item.quantity;
          return sum;
        }, 0);
      } else {
        return 0;
      }
    },
  },
  methods: {
    cartListsOpen() {
      cartListsOpen();
      this.$router.push(this.$route.fullPath + "#cart-modal");
    },
  },
};
</script>
<style scoped>
.headerIcon .info {
  position: unset;
  left: unset;
  top: unset;
  font-size: 11px;
}
</style>
