<template>
  <section class="recommended-products pt-3 mt-5 mb-5">
    <div class="d-flex flex-wrap justify-content-between align-items-baseline">
      <h6 class="font-weight-bold text-color-444 ml-3 fontsize-large">
        <span style="font-weight: 900; font-size: 27px"> محصولات </span>
        <span> کفش یونیک </span>
      </h6>
      <div class="recommended-products-category d-flex">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li
            v-for="(tab, index) in tabs"
            @click="setActiveProducts(tab)"
            :key="index"
            @mouseenter="loadTab(tab.name)"
            class="nav-item ml-1"
            role="presentation"
          >
            <a
              class="nav-link py-1 px-md-3 px-2 fontsize-large fontsize-md-17 bg-none"
              :class="{ active: activeTab == tab.name }"
              id="home-tab"
              aria-controls="home"
              aria-selected="true"
              >{{ tab.label }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="recommended-content tab-content mt-5" id="myTabContent">
      <div
        v-if="homeData && homeData[activeTab] && homeData[activeTab].length"
        class="recommended-content-item tab-pane fade show active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div class="home-product-list">
          <Product
            v-for="(product, index) in getEnough(homeData[activeTab])"
            :key="index"
            :product="product"
          ></Product>
        </div>

        <div class="text-center view-more">
          <router-link
            :to="activeRoute"
            class="recommended-products-more view-more fontsize-small text-white bg-color-theme px-3 py-2 mt-3 d-inline-block"
            >مشاهده همه محصولات</router-link
          >
        </div>
      </div>
      <div v-else class="d-flex align-items-center justify-content-center">
        <p class="fontsize-medium">محصولی یافت نشد.</p>
      </div>
    </div>
  </section>
</template>

<script>
import Product from "@/parts/Front/components/product/Product";
import { getImage } from "@/libraries/functions";

export default {
  name: "RecommendedProduct",
  components: {
    Product,
  },
  data() {
    return {
      readyProducts: null,
      activeRoute: "/products?sort=most_discount",
      tabs: [
        {
          name: "new_products",
          label: "محصولات جدید",
        },
        {
          name: "mostDiscount",
          label: "شگفت‌انگیز ها",
        },
        {
          name: "mostSales",
          label: "پرفروش ترین",
        },
      ],
      activeTab: "new_products",
      loadedTabs: ["new_products"],
    };
  },
  inject: ["mediaQueries"],
  computed: {
    showCount() {
      if (this.mediaQueries.xxxLarge) {
        return 8;
      }
      if (this.mediaQueries.xxLarge) {
        return 8;
      }
      if (this.mediaQueries.xLarge) {
        return 8;
      }
      if (this.mediaQueries.medium) {
        return 6;
      }
      return 6;
    },
    homeData() {
      if (this.$store.getters["front/getHomeData"]) {
        return this.$store.getters["front/getHomeData"];
      } else {
        return null;
      }
    },
  },
  watch: {},
  methods: {
    // گرفتن مقدار لازم براساس تعدادی که باید نمایش بدیم بر اساس سایز صفحه
    getEnough(array) {
      return [...array].splice(0, Math.min(array.length, this.showCount));
    },
    getProductMainImageUrl(product) {
      return getImage(product.major_image, "lg");
    },
    loadTab(name) {
      if (this.loadedTabs.includes(name)) {
        return;
      }
      this.loadedTabs.push(name);
    },
    setActiveProducts(tab) {
      if (tab.name == "new_products") {
        this.activeRoute = "/products?sort=top_sales";
      } else if (tab.name == "mostDiscount") {
        this.activeRoute = "/products?sort=most_discount";
      } else {
        this.activeRoute = "/products";
      }
      this.activeTab = tab.name;
    },
  },
};
</script>

<style scoped>
.view-more {
  padding: 7px 16px !important;
  border-radius: 10px !important;
  margin-top: 1rem;
}
.nav-link {
  color: #000000;
  font-size: 16px !important;
  font-weight: 800;
}
.nav-link {
  border-radius: unset !important;
}
@media screen and(max-width: 500px) {
  .recommended-products-category a.fontsize-medium {
    font-size: 13px !important;
  }
}
.home-product-list {
  display: grid;
  grid-template-columns: repeat(4, 23%);
  gap: 1.5rem calc((100% - (4 * 23%)) / 3);
}
@media (max-width: 992px) {
  .home-product-list {
    display: grid;
    grid-template-columns: repeat(3, 31%);
    gap: 1.5rem calc((100% - (3 * 31%)) / 2);
  }
}
@media (max-width: 768px) {
  .home-product-list {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    gap: 1.5rem calc((100% - (2 * 48%)));
  }
}
@media (max-width: 360px) {
  .home-product-list {
    display: grid;
    grid-template-columns: repeat(1, 100%);
  }
}
</style>
